import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide19/image/imgPortadaGuia19.png"
import img1 from "@components/pageGuide/guides/guide19/image/img1.png"
import slider1 from "@components/pageGuide/guides/guide19/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide19/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide19/image/silder3.png"
const title = (
  <>
   <h1 className="titles-underlined-ebook ebook-ventas">
      Cómo mejorar la segmentación en campañas de telemarketing
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      La segmentación telemarketing implica analizar datos demográficos,
      comportamiento del consumidor, preferencias e historial de compras para
      identificar grupos de personas con necesidades, intereses y
      características similares.{" "}
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Al dividir la audiencia en segmentos más pequeños y enfocados, las empresas
    pueden adaptar sus mensajes y ofrecer productos o servicios relevantes a
    cada grupo objetivo.
    <br /> <br />
    Esto no solo mejora la efectividad de las campañas, sino que también aumenta
    la satisfacción del cliente al recibir ofertas personalizadas y relevantes.
    Esta estrategia permite dirigirse a un público objetivo más específico y
    aumentando las posibilidades de éxito.
  </p>
)

const data = {
  start: {
    support: "Guía | Telemarketing ",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Importancia de la segmentación en telemarketing.",
      },
      {
        key: 2,
        text: "Segmentación por el tamaño de empresa.",
      },
      {
        key: 3,
        text: "Delimitar información por puesto e industria.",
      },
      {
        key: 4,
        text: "Identificar al cliente potencial por ubicación.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
