import React from "react"
import TemplatePageGuide  from "@components/pageGuide/template"
import { Helmet } from "react-helmet"
import data from "@components/pageGuide/guides/guide19/dataPageGuide19"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>
        Cómo mejorar la segmentación en campañas de telemarketing
       [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/recursos/guias/segmentacion-telemarketing/`}
      />
      <meta name="robots" content="index, follow"></meta>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Mejora la experiencia del usuario con chatbots personalizados y resuelve sus necesidades de manera eficiente. "
      />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content="Cómo mejorar la segmentación en campañas de telemarketing"
      />
      <meta
        property="og:url"
        content={`https://beexcc.com/recursos/guias/segmentacion-telemarketing/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/open-Beex.png`}
      />
      <meta
        property="og:description"
        content="Optimiza tu estrategia de telemarketing con la segmentación adecuada. Alcanza a tu público objetivo de manera efectiva y aumenta tus resultados."
      />
    </Helmet>
    <TemplatePageGuide
      ebook="Telemarketing"
      location={location}
      typeGuide="guide19"
      clickHereHref="https://beexcc.com/blog/noticias-atencion-al-cliente/"
      data={data}
    />
  </div>
)

export default IndexPage
